import React from 'react';
import bgimage from '../../img/banner2.jpg';
import { IoCallSharp } from 'react-icons/io5';
import { MdEmail } from 'react-icons/md';
import { FaInstagramSquare, FaFacebook } from 'react-icons/fa';
import { IoLogoWhatsapp } from 'react-icons/io';

function Homepage() {
    return (
        <div className="bg-cover bg-center min-h-screen flex items-center justify-center" style={{ backgroundImage: `url(${bgimage})` }}>
            <div className="p-10 bg-white bg-opacity-60 flex flex-col md:flex-row items-center md:space-x-8 rounded-lg shadow-lg">
                <div className="text-center max-w-lg mx-auto mb-6 md:mb-0">
                    <h1 className="text-4xl md:text-5xl font-bold text-green-600 mb-6">
                        Innovating Agriculture for a Better Tomorrow
                    </h1>
                    <div className="flex justify-center space-x-4 mt-4">
                        <button
                            type="button"
                            className="inline-block rounded-full bg-white p-3 text-xl text-[#299B46] shadow-md transition-transform transform hover:scale-125 focus:outline-none"
                        >
                            <IoCallSharp />
                        </button>
                        <button
                            type="button"
                            className="inline-block rounded-full bg-white p-3 text-xl text-[#E04D3B] shadow-md transition-transform transform hover:scale-125 focus:outline-none"
                        >
                            <MdEmail />
                        </button>
                        <button
                            type="button"
                            className="inline-block rounded-full bg-white p-3 text-xl text-[#F62468] shadow-md transition-transform transform hover:scale-125 focus:outline-none"
                        >
                            <FaInstagramSquare />
                        </button>
                        <button
                            type="button"
                            className="inline-block rounded-full bg-white p-3 text-xl text-[#4064AC] shadow-md transition-transform transform hover:scale-125 focus:outline-none"
                        >
                            <FaFacebook />
                        </button>
                        <button
                            type="button"
                            className="inline-block rounded-full bg-white p-3 text-xl text-[#4FC65C] shadow-md transition-transform transform hover:scale-125 focus:outline-none"
                        >
                            <IoLogoWhatsapp />
                        </button>
                    </div>
                </div>
                <div className="text-left max-w-lg mx-auto mt-6 md:mt-0">
                    <p className="text-lg mb-6">
                        At Planet Agro Industries, we're dedicated to transforming
                        agriculture through cutting-edge plastic solutions. Join us in
                        growing a sustainable future for farmers and communities
                        with our innovative products that boost productivity and
                        improve livelihoods.
                    </p>
                    <div className="flex flex-col md:flex-row items-center md:space-x-4">
                        <input
                            type="email"
                            placeholder="Send Your Email Address"
                            className="p-3 rounded-lg border border-gray-300 focus:outline-none focus:ring-2 focus:ring-green-600 mb-4 md:mb-0 md:w-96"
                        />
                        <button className="p-3 bg-green-600 text-white rounded-lg shadow-md hover:bg-green-700 focus:outline-none">
                            Get Started
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Homepage;
