import React from 'react';
import mulchImage from '../img/mul7.jpg';
import bgImage from '../img/mul9.jpg';
import logo from '../img/Service2.png';

import { FaTools, FaRecycle, FaShippingFast } from 'react-icons/fa';
import app1 from '../img/app1.png';
import app2 from '../img/app2.png';
import app3 from '../img/app3.png';
import app4 from '../img/app4.png';
import app5 from '../img/app5.png';
import Agricultural from '../img/mul8.jpg';

const LDPEFilms = () => {
  const applications = [
    {
      img: app1,
      head: "Medical",
    },
    {
      img: app2,
      head: "Transportation",
    },
    {
      img: app3,
      head: "Industrial Films and Adhesives",
    },
    {
      img: app4,
      head: "Packaging",
    },
    {
      img: app5,
      head: "Food and Beverage",
    },
  ];

  return (
    <div>
      <div className="bg-cover bg-center h-96 flex items-center justify-center text-[#0081C5]" style={{ backgroundImage: `url(${bgImage})` }}>
        <h1 className="text-4xl font-bold bg-gray-100 bg-opacity-50 rounded-lg p-6 z-10">LDPE Films</h1>
      </div>

      <div className="bg-green-700 shadow-lg rounded-lg mx-4 md:mx-auto mt-8 md:mt-0">
        <div className="flex flex-wrap items-center">
          <div className="w-full md:w-1/2 mb-4 md:mb-0 relative">
            <img src={mulchImage} alt="Plastic Mulch" className="w-full h-64 object-cover rounded-lg shadow-lg md:rounded-r-full" />
            <div className="absolute top-4 right-4 md:top-20 md:-right-8 bg-white rounded-full p-2">
              <img src={logo} alt="Logo" className="w-12 h-12" />
            </div>
          </div>

          <div className="w-full md:w-1/2 md:pl-8">
            <h2 className="text-3xl font-bold text-white mb-4 px-8">Introduction to LDPE Films</h2>
            <p className="text-lg leading-relaxed text-gray-100 px-8">
            LDPE (Low-Density Polyethylene) films are versatile materials known for their clarity, flexibility, and suitability in various packaging applications, including agriculture.            </p>
          </div>
        </div>
      </div>

      <div className="container mx-auto px-4 py-8">
      <div className="md:flex">
        <div className="md:w-1/2 mb-4 md:mb-0">
          <div className="p-8">
            <h1 className="text-2xl font-bold mb-4">Agricultural Usages</h1>
            <p className="mb-2 font-semibold">Mulch Films:</p>
            <ul className="list-disc pl-5 text-gray-700">
              <li>Used for soil temperature regulation, moisture conservation, and weed suppression.</li>
              <li>Enhances crop growth and yield in various agricultural settings.</li>
            </ul>
            <p className="mb-2 font-semibold mt-4">Greenhouse Coverings:</p>
            <ul className="list-disc pl-5 text-gray-700">
              <li>Controls environmental factors like temperature and humidity to optimize plant growth.</li>
            </ul>
            <p className="mb-2 font-semibold mt-4">Packaging:</p>
            <ul className="list-disc pl-5 text-gray-700">
              <li>Ensures freshness and extends shelf life for fruits, vegetables, and other perishable goods.</li>
            </ul>
          </div>
        </div>
        <div className="md:w-1/2">
          <div className="container mx-auto px-4 py-8">
            <img src={Agricultural} alt="Agricultural Usages" className="mx-auto rounded-lg shadow-lg w-96" />
          </div>
        </div>
      </div>
    </div>

    <div className="container mx-auto px-4 py-8">
        <h1 className="text-3xl font-bold mb-4 text-center">Applications</h1>
        <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-5 gap-6">
          {applications.map((app, index) => (
            <div key={index} className="text-center">
              <div className="bg-green-500 rounded-full p-4 mx-auto mb-2" style={{ width: 'fit-content' }}>
                <img src={app.img} alt='' className='h-14 w-14 mx-auto' />
              </div>
              <h2 className="text-lg font-semibold">{app.head}</h2>
            </div>
          ))}
        </div>
      </div>
      <div className="container mx-auto px-4 py-8">
        <h1 className="text-3xl font-bold mb-4 text-center">Benefits of LDPE Films</h1>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
          <div className="bg-gray-100 rounded-lg shadow-lg p-6  ">
            <div className="flex items-center mb-4">
              <FaTools className="text-green-500 w-6 h-6" />
              <span className="ml-2 font-bold text-xl">Versatility</span>
            </div>
            <p className="text-gray-700">LDPE films are versatile, suitable for a wide range of agricultural and packaging applications.</p>
          </div>
          <div className="bg-gray-100 rounded-lg shadow-lg p-6  ">
            <div className="flex items-center mb-4">
              <FaRecycle className="text-green-500 w-6 h-6  " />
              <span className="ml-2 font-bold text-xl">Recyclability</span>
            </div>
            <p className="text-gray-700">LDPE films can be recycled, contributing to sustainable packaging solutions.</p>
          </div>
          <div className=" rounded-lg shadow-lg p-6  bg-gray-100">
            <div className="flex items-center mb-4">
              <FaShippingFast className="text-green-500 w-6 h-6" />
              <span className="ml-2 font-bold text-xl">Cost-Effective</span>
            </div>
            <p className="text-gray-700">LDPE films help reduce packaging costs due to their lightweight nature.</p>
          </div>
        </div>
      </div>

      <div className="container mx-auto px-4 py-8">
        <h1 className="text-3xl font-bold mb-4 text-center">Why Choose LDPE Films from Planet Agro Industries?</h1>
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
          <div className="bg-white rounded-lg shadow-lg p-6  border-emerald-100 border-2">
            <h2 className="text-xl font-bold mb-2">Quality Assurance</h2>
            <p className="text-gray-700">Planet Agro Industries ensures high-quality LDPE films through rigorous quality control measures.</p>
          </div>
          <div className="bg-white rounded-lg shadow-lg p-6  border-emerald-100 border-2">
            <h2 className="text-xl font-bold mb-2">Customization Options</h2>
            <p className="text-gray-700">We offer LDPE films in various blends, thicknesses, and specifications tailored to your specific needs.</p>
          </div>
          <div className="bg-white rounded-lg shadow-lg p-6  border-emerald-100 border-2">
            <h2 className="text-xl font-bold mb-2">Environmental Responsibility</h2>
            <p className="text-gray-700">Our LDPE films are designed for recyclability and minimal environmental impact, supporting sustainable practices.</p>
          </div>
          <div className="bg-white rounded-lg shadow-lg p-6  border-emerald-100 border-2">
            <h2 className="text-xl font-bold mb-2">Customer Support</h2>
            <p className="text-gray-700">We provide dedicated customer support and technical expertise to meet your specific requirements.</p>
          </div>
        </div>
      </div>

      
    </div>

  );
};

export default LDPEFilms;
