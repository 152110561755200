import React from 'react'
import Navbar from '../Navbar/Navbar'
import Homepage from './Homepage/Homepage'
import Chose from './Homepage/Chose'
import Product from './Homepage/Product/Product'
import Fact from './Homepage/Fact'

function Home() {
  return (
    <div>
       <Homepage/>
       <Chose/>
       <Product/>
       <Fact/>
    </div>
  )
}

export default Home