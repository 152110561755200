import React from 'react';
import woman from '../img/hero.png';
import shap1 from '../img/shape-01.svg';
import shap2 from '../img/shape-02.svg';
import shap3 from '../img/shape-03.svg';
import shap4 from '../img/shape-04.svg';
import { FaCheckCircle, FaMoneyCheckAlt, FaCogs, FaTruck } from 'react-icons/fa';

function BusinessOffers() {
  const benefits = [
    { icon: <FaCheckCircle />, title: "Excellent Quality Products", description: "Trusted solutions for enhancing agricultural practices." },
    { icon: <FaMoneyCheckAlt />, title: "Transparent Transactions", description: "Clear and reliable business dealings." },
    { icon: <FaCogs />, title: "High Returns on Investments", description: "Profitable opportunities for your efforts." },
    { icon: <FaTruck />, title: "Reputable Logistics Partnerships", description: "Seamless supply chain operations." }
];
  return (
    <div>
      <section className="bg-gray-100 py-12">
        <div className="container mx-auto px-4 md:px-8 relative">
          <div className="relative top-0 right-[-10%] hidden 2xl:block">
            <img src={shap1} alt="shape" className="h-auto" />
          </div>
          <div className="absolute top-0 right-1/2 hidden 2xl:block transform translate-x-1/2 translate-y-1/4">
            <img src={shap2} alt="shape" className="h-auto" />
          </div>

          <div className="absolute bottom-0 right-1/2 hidden 2xl:block">
            <img src={shap3} alt="shape" className="h-auto" />
          </div>
          <div className="absolute hidden -top-10 md:block -right-16">
            <img src={shap4} alt="shape" className="h-auto" />
          </div>
          <div className="md:absolute -top-10 md:bottom-0 md:-right-16 md:h-auto md:max-w-[50%]">
            <img src={woman} alt="Woman" className="w-full h-auto" />
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-8 items-center mt-8">
            <div className="md:ml-8">
              <div className="text-center md:text-left animate-left delay-200">
                <h1 className="text-4xl md:text-5xl font-bold text-blue-700 mb-4 leading-tight">Explore New Opportunities in Agricultural Plastic Products with Planet Agro Industries</h1>
                <p className="text-gray-700 leading-relaxed mb-6">
                  We invite business tie-ups from all over India and the rest of the world to explore new business possibilities and opportunities. We welcome business houses, individuals, and industries from India and abroad to market our products in their regions. Our business associates will surely benefit from our experience and fine products. A tie-up with us will also give you advantages such as:
                </p>
                <div className="text-center flex  md:text-left">
                  <a href="#" className="inline-block bg-blue-500 hover:bg-blue-600 text-white py-3 px-8 rounded-full mr-4 transition duration-300 ease-in-out">
                    Get Started Now
                  </a>
                  <div className="text-gray-700">
                    <p className="font-bold">
                      Call us: +91 9307728959 | +91 9822533386
                    </p>
                    <br className="block md:hidden" />
                    <p className="hidden md:inline-block">For any questions or concerns</p>
                  </div>
                </div>

              </div>
            </div>
          </div>

        </div>
      </section>

      <div className="">

           
          </div>
          <div className="bg-white p-8  container mx-auto">
            <h2 className="text-2xl font-bold text-gray-800 mb-4 text-center mt-20">Benefits of Partnering with Us</h2>
            <p className="text-lg mb-4 ">
              Planet Agro Industries invites you to join us in revolutionizing agriculture with our high-quality agricultural plastic products. With a legacy of nearly half a century in the plastics and polymers industry, we specialize in manufacturing and distributing innovative solutions such as Mulch Film and Warp Knit Fabric, designed to enhance agricultural practices and increase yields.
            </p>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                {benefits.map((benefit, index) => (
                    <div key={index} className=" bg-white p-4 shadow-lg rounded-md border-2 border-blue-200">
                        <div className="flex items-center mb-2">
                            <span className="text-3xl text-blue-500 mr-2">{benefit.icon}</span>
                            <h3 className="text-lg font-semibold text-gray-800">{benefit.title}</h3>
                        </div>
                        <p className="text-gray-600">{benefit.description}</p>
                    </div>
                ))}
            </div>

      </div>
    </div>
  );
}

export default BusinessOffers;
