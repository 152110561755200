import React, { useState, useEffect } from 'react';
import { FaAward, FaUserTie, FaTasks } from 'react-icons/fa';
import bgimage from '../../img/counter2.jpg';

function Fact() {
    const [experience, setExperience] = useState(0);
    const [customers, setCustomers] = useState(0);
    const [varieties, setVarieties] = useState(0);

    useEffect(() => {
        const incrementValues = () => {
            let counter = 0;
            const interval = setInterval(() => {
                counter++;
                setExperience(counter * 4); 
                setCustomers(counter * 110); 
                setVarieties(counter * 1600); 

                if (counter * 4 >= 4) {
                    clearInterval(interval);
                }
            }, 1000);

            return () => clearInterval(interval);
        };

        incrementValues();
    }, []);


    return (
        <div style={{ backgroundImage: `url(${bgimage})` }}>
            <div className='bg-green-500 bg-opacity-75 py-12 text-white text-center'>
            <div className='grid  md:flex justify-evenly'>
                <div className=''>
                    <h1 className='text-4xl font-bold mb-8'>Our Fun Facts</h1>
                    <h4 className='mb-8 text-2xl p-2 '>Contact us today for more information about our products and services.</h4>
                </div>
                <div className='flex justify-center gap-12 p-2'>
                    <div className='flex flex-col items-center'>
                        <FaAward className='text-5xl mb-2' />
                        <p className='text-3xl font-bold'>{experience}+</p>
                        <h2 className='text-xl'>Years of Experience</h2>
                    </div>
                    <div className='flex flex-col items-center'>
                        <FaUserTie className='text-5xl mb-2' />
                        <p className='text-3xl font-bold'>{customers}+</p>
                        <h2 className='text-xl'>Happy Customers</h2>
                    </div>
                    <div className='flex flex-col items-center'>
                        <FaTasks className='text-5xl mb-2' />
                        <p className='text-3xl font-bold'>{varieties}+</p>
                        <h2 className='text-xl'>Different Varieties</h2>
                    </div>
                </div>
                </div>
            </div>
        </div>
    );
}

export default Fact;
