import React from 'react'
import aboutus from '../img/About.jpg' 
import bgimage from '../img/service-bg.jpg' 

function About() {
  return (
    <div className='bg-white py-10 px-5 sm:px-10 lg:px-20' style={{ backgroundImage: `url(${bgimage})` }}>
      <h1 className='text-4xl font-bold text-center text-green-600 mb-4 '>About Planet Agro Industries</h1>
      <p className='text-xl text-center text-gray-700 mb-10'>Cultivating Innovation in Agriculture</p>
      <hr className='border-t-2 border-green-500 mb-10 animate-pulse'></hr>
      <div className='space-y-8'>
        <p className='text-lg text-gray-800 leading-relaxed'>
          Planet Agro Industries is a dynamic team of techno-commercial professionals with a collective experience spanning nearly half a century in the plastics and polymers industry. We specialize in the manufacturing and distribution of high-quality agricultural plastic products, including Mulch Film and Warp Knit Fabric. Our commitment to research, development, and innovation drives us to create products that enhance agricultural practices, ultimately leading to higher yields and improved livelihoods for farmers.
        </p>
        <p className='text-lg text-gray-800 leading-relaxed'>
          At Planet Agro Industries, we understand the critical role modern technology plays in agriculture. We pride ourselves on being steadfast supporters of the "Sons of Soil," providing them with the tools and resources needed to thrive in today’s agricultural landscape. Our products are designed not just to meet the needs of today but to anticipate and address the challenges of tomorrow.
        </p>
      </div>
      <div className='mt-10 flex flex-col lg:flex-row items-center'>
        <img src={aboutus} className='w-full lg:w-1/2 rounded-lg shadow-lg transform hover:scale-105 transition-transform duration-500' alt="About Us" />
        <div className='mt-8 lg:mt-0 lg:ml-10'>
          <div className='mb-8'>
            <h2 className='text-3xl font-semibold text-green-600 mb-4'>Our Vision</h2>
            <p className='text-lg text-gray-800 leading-relaxed'>
              We envision a future where modern farmers, empowered by our innovative products, enjoy prosperous and fulfilling lives. Our goal is to revolutionize agriculture, ensuring that farmers can maximize their productivity and achieve sustainable success.
            </p>
          </div>
          <div>
            <h2 className='text-3xl font-semibold text-green-600 mb-4'>Our Mission</h2>
            <p className='text-lg text-gray-800 leading-relaxed'>
              Our mission is to be a cornerstone in the agricultural industry, driving progress and development for modern farmers. We aim to provide cutting-edge solutions that support agricultural advancements, enabling farmers to optimize their operations and contribute to a thriving agricultural sector.
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default About
