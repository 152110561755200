import React from 'react';
import { Link } from 'react-router-dom';
import product1 from '../../../img/about_front.jpg';
import product2 from '../../../img/iStock-1427610220.jpg';
import product3 from '../../../img/mul8.jpg';
import product4 from '../../../img/silage-picture-tilak.jpg';
import product5 from '../../../img/bao-bi-mang-ghep-5.png';
import logo1 from '../../../img/service1.png';
import logo2 from '../../../img/Service3.png';
import logo3 from '../../../img/Service2.png';
import logo4 from '../../../img/Service2.png';
import logo5 from '../../../img/Service4.png';

function Product() {
  const products = [
    { product: product1, logo: logo1, title: 'Warp Knit Fabric', description: 'Warp Knit Fabrics are basically used to provide shade and to control sunlight in Green Houses and Nurseries and even in Agricultural Lands too.', link: '/warpkint' },
    { product: product2, logo: logo2, title: 'Plastic Mulch Paper', description: 'Mulch Paper is the process or practice of covering the soil/ground to make more favourable conditions for plant growth, development and efficient crop production.', link: '/plastickaper' },
    { product: product3, logo: logo3, title: 'LDPE Films', description: 'LDPE is the oldest and most mature of the polyethylenes (PE). It is characterised by its short and long chain branching which gives it good clarity and processability although', link: '/ldpefilms' },
    { product: product4, logo: logo4, title: 'Silage Stretch Film', description: 'Silage wrapping film is a type of agricultural plastic film used primarily in the farming industry, especially for wrapping hay, silage, and other forage crops.', link: '/slagestretchfilm' },
    { product: product5, logo: logo5, title: 'Lamination Film ', description: 'At Planet Agro Industries, we manufacture high-quality lamination films specifically designed for food packing. Our lamination films provide an essential layer of protection, ensuring the freshness, safety, and shelf life of various food products.', link: '/lamination' },
  ];

  return (
    <div className="bg-gray-50 py-12">
      <div className="text-center mb-12">
        <h4 className="text-lg font-semibold text-green-500">Products we make</h4>
        <h2 className="text-4xl font-bold text-gray-900">Our Products</h2>
      </div>
      <div className="flex flex-wrap justify-center gap-10">
        {products.map((item) => (
          <div className="relative w-full max-w-xs sm:max-w-sm md:max-w-md bg-white rounded-lg shadow-lg overflow-hidden transform transition duration-500 hover:scale-105 hover:shadow-2xl">
            <Link to={item.link} className="block">
              <div className="relative group">
                <img src={item.product} alt="Product Image" className="w-full h-96 object-cover" />
                <div className="absolute bottom-0 left-0 right-0 flex items-center justify-center group-hover:opacity-100 transition duration-500">
                  <img src={item.logo} alt="Logo Image" className="w-16 h-16 mb-2 shadow-lg bg-white p-2 rounded-full z-20" />
                  <h2 className="relative text-2xl font-bold text-white mb-2 ml-4 bg-green-500 bg-opacity-75 p-4 z-20">{item.title}</h2>
                </div>
                <div className="absolute h-full top-0 left-0 right-0 p-4 bg-black bg-opacity-50 text-center opacity-0 group-hover:opacity-100 transition duration-500">
                  <div className="text-center text-white px-4">
                    <h2 className="text-2xl font-bold mb-2">{item.title}</h2>
                    <p className="mb-4">{item.description}</p>
                    <button className="inline-block bg-white rounded-lg px-4 py-2 shadow-lg hover:bg-green-500 hover:text-white transition duration-300">
                      Learn More
                    </button>
                  </div>
                </div>
              </div>
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Product;
