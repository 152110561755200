import React from 'react';

function Quality() {
  return (
    <div className="container mx-auto py-8">
      <div className="bg-white text-4xl font-bold text-center text-green-600 mb-4">
        <h1 className="text-3xl font-bold mb-4">Quality Policy</h1>
        <p className="text-xl text-center text-gray-700 mb-10">Commitment to Excellence in Agricultural Plastics</p>
      </div>

      <hr className='border-t-2 border-green-500 mb-10 animate-pulse'></hr>

      <p className="text-gray-700 mb-6 px-6">
        At Planet Agro Industries, we are committed to providing our customers with high-quality plastic products that enhance advanced farming practices and contribute to the prosperity of farmers.
      </p>

      <div className="bg-white px-6 py-4 mb-6">
        <h3 className="text-xl font-bold mb-4">Objectives</h3>
        <ol className="list-decimal list-inside">
          <li className="text-gray-700 mb-2">
            <span className="font-bold">Cost Efficiency:</span> We strive to minimize production costs while maintaining high-quality standards.
          </li>
          <li className="text-gray-700 mb-2">
            <span className="font-bold">Continuous Improvement:</span> We are dedicated to continuously improving our products through innovation and feedback from our customers and stakeholders.
          </li>
          <li className="text-gray-700 mb-2">
            <span className="font-bold">Technological Advancement:</span> Our production processes leverage experienced manpower and cutting-edge technologies to ensure efficiency, consistency, and sustainability.
          </li>
          <li className="text-gray-700 mb-2">
            <span className="font-bold">Financial Empowerment:</span> We aim to make significant contributions to the financial upliftment of farmers by offering reliable and cost-effective agricultural solutions.
          </li>
        </ol>
      </div>

      <div className="bg-white px-6 py-4 mb-6">
        <h3 className="text-xl font-bold mb-4">Implementation</h3>
        <ul className="list-disc list-inside">
          <li className="text-gray-700 mb-2">
            <span className="font-bold">Quality Assurance:</span> Rigorous quality control measures are implemented at every stage of production to ensure adherence to international standards and customer expectations.
          </li>
          <li className="text-gray-700 mb-2">
            <span className="font-bold">Training and Development:</span> Continuous training and development programs are provided to our workforce to enhance skills and maintain competence in modern agricultural technologies.
          </li>
          <li className="text-gray-700 mb-2">
            <span className="font-bold">Customer Focus:</span> We prioritize customer satisfaction by understanding their needs and delivering products that exceed their expectations.
          </li>
        </ul>
      </div>

      <div className="bg-white px-6 py-4">
        <h3 className="text-xl font-bold mb-4">Commitment</h3>
        <p className="text-gray-700">
          We are committed to upholding these objectives and continuously improving our processes and products to support sustainable agriculture and foster long-term partnerships with our customers and stakeholders.
        </p>
      </div>
    </div>
  );
}

export default Quality;
