import React from 'react'
import mulchImage from '../img/bao-bi-mang-ghep-2.jpg';
import bgImage from '../img/download.png';
import logo from '../img/Service4.png';
import app1 from '../img/lam5.jpg';
import app2 from '../img/lam1.jpg';
import app3 from '../img/lam2.jpg';
import app4 from '../img/lam3.jpg';
import Agricultural from '../img/bao-bi-mang-ghep-5.png';

function Lamination() {
    const applications = [
        { img: app1, head: "Pillow Bag Packing" },
        { img: app2, head: "Side Gusset Pouch" },
        { img: app3, head: "Bar Wrapper" },
        { img: app4, head: "Sachet Packing" },
    ];

    return (
        <div>
            <div className="bg-cover bg-center h-96 flex items-center justify-center text-[#0081C5]" style={{ backgroundImage: `url(${bgImage})` }}>
                <h1 className="text-4xl font-bold bg-gray-100 bg-opacity-50 rounded-lg p-6 z-10">Lamination Film for Food Packing</h1>
            </div>

            <div className="bg-green-700 shadow-lg rounded-lg mx-4 md:mx-auto mt-8 md:mt-0 p-6">
                <div className="flex flex-wrap items-center">
                    <div className="w-full md:w-1/2 mb-4 md:mb-0 relative">
                        <img src={mulchImage} alt="Plastic Mulch" className="w-full h-64 object-cover rounded-lg shadow-lg md:rounded-r-full" />
                        <div className="absolute top-4 right-4 md:top-20 md:-right-8 bg-white rounded-full p-2">
                            <img src={logo} alt="Logo" className="w-12 h-12" />
                        </div>
                    </div>
                    <div className="w-full md:w-1/2 md:pl-8">
                        <h2 className="text-3xl font-bold text-white mb-4">Introduction to Lamination Film for Food Packing</h2>
                        <p className="text-lg leading-relaxed text-gray-100">
                            At Planet Agro Industries, we manufacture high-quality lamination films specifically designed for food packing. Our lamination films provide an essential layer of protection, ensuring the freshness, safety, and shelf life of various food products. With our advanced manufacturing techniques and commitment to quality, our lamination films are the ideal choice for all your food packaging needs.
                        </p>
                    </div>
                </div>
            </div>

            <div className="container mx-auto px-4 py-8">
                <div className="md:flex">
                    <div className="md:w-1/2 mb-4 md:mb-0">
                        <div className="p-8">
                            <h1 className="text-2xl font-bold mb-4">Usages</h1>
                            <p className="mb-2 font-semibold">Our lamination films are versatile and can be used for a wide range of food packing applications, including:</p>
                            <ul className="list-disc pl-5 text-gray-700">
                                <li>Snack Packaging: Ensures the crispness and freshness of snacks like chips and biscuits.</li>
                                <li>Dairy Products: Provides a protective barrier for cheese, butter, and other dairy items.</li>
                                <li>Meat and Poultry: Maintains the quality and safety of fresh and processed meats.</li>
                                <li>Bakery Items: Keeps baked goods such as bread, pastries, and cakes fresh.</li>
                                <li>Frozen Foods: Offers excellent barrier properties to protect frozen foods from moisture and freezer burn.</li>
                                <li>Ready-to-Eat Meals: Ensures the integrity and freshness of packaged meals.</li>
                            </ul>
                        </div>
                    </div>
                    <div className="md:w-1/2">
                        <img src={Agricultural} alt="Agricultural Usages" className="mx-auto rounded-lg shadow-lg w-96" />
                    </div>
                </div>
            </div>

            <div className="container mx-auto px-4 py-8">
                <h1 className="text-3xl font-bold mb-4 text-center">Applications</h1>
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-6">
                    {applications.map((app, index) => (
                        <div key={index} className="text-center">
                            <img src={app.img} alt={app.head} className="w-full h-auto object-cover rounded-lg shadow-lg mb-4" />
                            <h2 className="text-xl font-semibold">{app.head}</h2>
                        </div>
                    ))}
                </div>
            </div>

            <div className="container mx-auto px-4 py-8">
                <h1 className="text-3xl font-bold mb-4 text-center">Benefits of Lamination Film for Food Packing</h1>
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
                    <div className="bg-gray-100 rounded-lg shadow-lg p-6">
                        <div className="flex items-center mb-4">
                            <i className="fas fa-clock text-green-500 w-6 h-6"></i>
                            <span className="ml-2 font-bold text-xl">Enhanced Shelf Life:</span>
                        </div>
                        <p className="text-gray-700">Our films provide an effective barrier against moisture, oxygen, and other external factors, significantly extending the shelf life of food products.</p>
                    </div>
                    <div className="bg-gray-100 rounded-lg shadow-lg p-6">
                        <div className="flex items-center mb-4">
                            <i className="fas fa-shield-alt text-green-500 w-6 h-6"></i>
                            <span className="ml-2 font-bold text-xl">Product Safety:</span>
                        </div>
                        <p className="text-gray-700">The protective layer of our lamination films ensures that food products remain uncontaminated and safe for consumption.</p>
                    </div>
                    <div className="bg-gray-100 rounded-lg shadow-lg p-6">
                        <div className="flex items-center mb-4">
                            <i className="fas fa-eye text-green-500 w-6 h-6"></i>
                            <span className="ml-2 font-bold text-xl">Visual Appeal:</span>
                        </div>
                        <p className="text-gray-700">Our films offer excellent clarity and gloss, enhancing the visual appeal of packaged foods and making them more attractive to consumers.</p>
                    </div>
                    <div className="bg-gray-100 rounded-lg shadow-lg p-6">
                        <div className="flex items-center mb-4">
                            <i className="fas fa-hands-helping text-green-500 w-6 h-6"></i>
                            <span className="ml-2 font-bold text-xl">Convenience:</span>
                        </div>
                        <p className="text-gray-700">Lamination films are easy to handle and can be seamlessly integrated into existing packaging lines, improving efficiency and reducing operational costs.</p>
                    </div>
                    <div className="bg-gray-100 rounded-lg shadow-lg p-6">
                        <div className="flex items-center mb-4">
                            <i className="fas fa-cubes text-green-500 w-6 h-6"></i>
                            <span className="ml-2 font-bold text-xl">Versatility:</span>
                        </div>
                        <p className="text-gray-700">Suitable for a wide range of food products, our films provide consistent performance across different packaging applications.</p>
                    </div>
                </div>
            </div>

            <div className="container mx-auto px-4 py-8">
                <h1 className="text-3xl font-bold mb-4 text-center">Advantages of Choosing Lamination Film for Food Packing at Planet Agro Industries</h1>
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
                    <div className="bg-white rounded-lg shadow-lg p-6 border-emerald-100 border-2">
                        <h2 className="text-xl font-bold mb-2">Superior Quality:</h2>
                        <p className="text-gray-700">Our lamination films are made from premium materials that ensure durability and reliability.</p>
                    </div>
                    <div className="bg-white rounded-lg shadow-lg p-6 border-emerald-100 border-2">
                        <h2 className="text-xl font-bold mb-2">Advanced Technology:</h2>
                        <p className="text-gray-700">We utilize state-of-the-art technology to produce films with excellent barrier properties, enhancing product shelf life.</p>
                    </div>
                    <div className="bg-white rounded-lg shadow-lg p-6 border-emerald-100 border-2">
                        <h2 className="text-xl font-bold mb-2">Customization:</h2>
                        <p className="text-gray-700">We offer customizable solutions to meet specific packaging requirements, including different sizes, thicknesses, and properties.</p>
                    </div>
                    <div className="bg-white rounded-lg shadow-lg p-6 border-emerald-100 border-2">
                        <h2 className="text-xl font-bold mb-2">Sustainability:</h2>
                        <p className="text-gray-700">Our lamination films are designed with sustainability in mind, offering recyclable options to reduce environmental impact.</p>
                    </div>
                    <div className="bg-white rounded-lg shadow-lg p-6 border-emerald-100 border-2">
                        <h2 className="text-xl font-bold mb-2">Regulatory Compliance:</h2>
                        <p className="text-gray-700">All our films comply with international food safety standards, ensuring the highest level of safety and hygiene for your products.</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Lamination ;
