import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import About from "./Component/About/About";
import Contact from "./Component/Contact/Contact";
import Home from "./Component/Home/Home";
import Navbar from "./Component/Navbar/Navbar";
import LDPEFilms from "./Component/Product/LDPEFilms";
import PlasticPaper from "./Component/Product/PlasticPaper"; 
import WarpKint from "./Component/Product/WarpKint";
import Quality from "./Component/Other/Quality";
import BusinessOffers from "./Component/Other/BusinessOffers";
import Footer from "./Component/Contact/Footer";
import "./index.css";
import SilageStretchFilm from "./Component/Product/SilageStretchFilm";
import Lamination from "./Component/Product/Lamination Film";

function App() {
  return (
    <div className="App">
      <Router>
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/ldpefilms" element={<LDPEFilms />} />
          <Route path="/plastickaper" element={<PlasticPaper />} />
          <Route path="/warpkint" element={<WarpKint />} />
          <Route path="/quality" element={<Quality />} />
          <Route path="/businessoffers" element={<BusinessOffers />} />
          <Route path="/slagestretchfilm" element={<SilageStretchFilm />} />
          <Route path="/lamination" element={<Lamination />} /> 
        </Routes>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
